<template>
  <el-popover width="428" trigger="click" popper-class="popoverToolsNotifications tools-popup" v-model="isClose">
    <button
      slot="reference"
      class="transition-colors duration-300 bg-white h-9 w-9 flex items-center justify-center button tools-popup__btn"
      type="button"
    >
      <gear-icon />
    </button>

    <h2 class="tools-popup__title font-SourceSansPro">{{ $t('hide.notifications') }}</h2>

    <button class="tools-popup__close" @click="isClose = !isClose">
      <i
        class="el-icon-close pointer monitoring__closeIcon monitoring__closeIcon-small"
      />
    </button>

    <div class="tools-popup__group">
      <p class="tools-popup__text font-SourceSansPro">
        {{ $t('hide.notifications.text') }}
      </p>

      <skif-switch
        v-model="toggleNotification"
        class="item-button"
        :is-big="true"
        @click.native.stop
        @change="hideNotification"
      >
        {{ $t('hide') }}
      </skif-switch>
    </div>
  </el-popover>
</template>

<script>
import { mapState } from 'vuex';
import GearIcon from '@/components/map/tools/images/GearIcon.vue';

export default {
  components: {
    GearIcon
  },

  data: () => ({
    toggleNotification: '',
    isClose: false,
  }),

  created() {
    this.toggleNotification = JSON.parse(
      localStorage.getItem('hideNotification')
    );
    this.$store.commit('map/TOGGLE_NOTIFICATION', this.toggleNotification);
  },

  computed: {
    ...mapState('map', {
      isHidingNotification: (state) => state.isHidingNotification,
    }),
  },

  methods: {
    hideNotification() {
      localStorage.setItem(
        'hideNotification',
        JSON.stringify(this.toggleNotification)
      );
      this.$store.commit('map/TOGGLE_NOTIFICATION', this.toggleNotification);
    },
  },

  watch: {
    isHidingNotification(val) {
      this.toggleNotification = val;
    },
  }
}

</script>
